import React from "react";
import { css, styled } from "goober";

import { classNames } from "@/util/class-names.mjs";

const HaloContainer = styled("div")`
  display: block;

  width: var(--size);
  aspect-ratio: 1;

  opacity: var(--opacity);

  background: radial-gradient(
    circle at 50% 50%,
    rgba(0, 0, 0, 0) calc(70% - var(--thickness) - var(--blur) * 2),
    rgba(53, 56, 64, 1) calc(70% - var(--thickness) - var(--blur)),
    rgba(53, 56, 64, 1) calc(70% - var(--blur)),
    rgba(0, 0, 0, 0) calc(70%)
  );

  &.fade-out {
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.72) 28%,
      rgba(0, 0, 0, 0)
    );
  }
`;

type HaloOptions = {
  size?: number | string;
  thickness?: number | string;
  blur?: number | string;

  opacity?: number;
  fadeOut?: boolean;
} & React.ComponentPropsWithoutRef<"div">;

export function Halo({
  size,
  thickness,
  blur,
  opacity,
  fadeOut,
  className,
  ...rest
}: HaloOptions) {
  return (
    <HaloContainer
      {...classNames(
        fadeOut && "fade-out",
        css`
          --size: ${typeof size === "number" ? size + "px" : (size ?? "100%")};
          --thickness: ${typeof thickness === "number"
            ? thickness + "px"
            : (thickness ?? "5px")};
          --blur: ${typeof blur === "number" ? blur + "px" : (blur ?? "18px")};
          --opacity: ${opacity ?? "0.2"};
        `,
        className,
      )}
      {...rest}
    ></HaloContainer>
  );
}

import { styled } from "goober";
import {
  desktop,
  mobile,
  mobileMedium,
  tabletLarge,
} from "clutch/src/Style/style.mjs";

export const PageContainer = styled("div")`
  --section-gap: var(--sp-16);
  --content-max-width: min(1200px, 100vw);

  width: 100%;
  margin: 0 auto;

  footer {
    margin-top: var(--sp-24);
  }
`;

const Section = styled("section")`
  width: 100%;
  margin-inline: auto;

  ${desktop} {
    padding-inline: var(--sp-12);
  }

  ${mobile} {
    padding-inline: var(--sp-6);
  }
`;

export const MainSections = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--section-gap);

  overflow: hidden;
`;

export const HeaderSection = styled(Section)`
  display: flex;
  flex-direction: column;

  max-width: var(--content-max-width);
  margin-top: var(--section-gap);

  color: var(--white);

  & > .video-block {
    position: relative;

    width: 100%;

    video {
      display: block;

      width: 100%;
      max-width: 332px;
      margin-inline: auto;
      margin-bottom: -40px;
    }

    & > div {
      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      ${mobile} {
        display: none;
      }
    }
  }

  & > .text-block {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    isolation: isolate;

    h1 {
      font-size: var(--sp-15);
      font-weight: 400;

      ${tabletLarge} {
        font-size: var(--sp-12);
      }

      ${mobile} {
        font-size: var(--sp-7);
      }
    }

    p {
      font-weight: 400;
      font-size: var(--sp-7);
      line-height: var(--sp-10);
      letter-spacing: -0.009em;
    }
  }
`;

export const ReasoningSection = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: var(--sp-8);

  max-width: var(--content-max-width);

  color: var(--white);

  h2 {
    text-align: center;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--sp-3);

    text-align: center;

    ${tabletLarge} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${mobile} {
      grid-template-columns: repeat(2, 1fr);
    }

    ${mobileMedium} {
      grid-template-columns: 1fr;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--sp-6);

      padding: var(--sp-7_5);

      background: var(--shade7);
      border-radius: var(--sp-2);
      box-shadow:
        0px 1px 4px hsla(var(--shade1-hsl) / 0.1),
        inset 0px 1px 0px hsla(var(--shade1-hsl) / 0.1);

      & > .icon-block {
        display: flex;
        align-items: center;
        justify-content: center;

        width: var(--sp-18);
        height: var(--sp-18);

        border-radius: var(--sp-2);
        background: var(--shade6);
        box-shadow: inset 0px 1px 0px hsla(var(--shade1-hsl) / 0.1);

        svg {
          width: var(--sp-10_5);
          height: var(--sp-10_5);
        }
      }

      & > .text-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--sp-1);

        p {
          color: var(--shade1);
        }
      }
    }
  }
`;

export const JoinSection = styled(Section)`
  margin-block: var(--section-gap);

  color: var(--white);

  background: linear-gradient(
    102.98deg,
    rgb(255, 17, 45) -0.14%,
    rgb(207, 16, 80) 100%
  );

  text-align: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-2_5);
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: var(--sp-30) 0;

    h3 {
      font-weight: 500;
      font-size: var(--sp-3);
      text-transform: uppercase;
    }

    p {
      font-weight: 400;
      font-size: var(--sp-7);
      line-height: var(--sp-10);
    }
  }
`;

export const OpenPositionsSection = styled(Section)`
  container-type: inline-size;
  container-name: open-positions;
  max-width: var(--content-max-width);
  padding-block-end: var(--sp-4);

  .container {
    display: flex;
    gap: var(--sp-8);
    align-items: flex-start;

    @container (inline-size <= 750px) {
      display: grid;
    }
  }
`;

export const DepartmentsListPanel = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--sp-4);
  padding: var(--sp-6);
  background: var(--shade8);
  border-radius: var(--sp-2);

  @container (inline-size <= 750px) {
    padding: 0;
    background: transparent;
  }

  & > .input-block {
    flex: 1;
  }

  p {
    color: var(--shade1);
  }
`;

export const DepartmentList = styled("div")`
  display: grid;
  gap: var(--sp-4);

  @container (inline-size <= 750px) {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sp-2);
  }
`;

export const DepartmentButton = styled("label")`
  --radii: var(--br);
  position: relative;
  cursor: pointer;

  > * {
    position: relative;
  }

  > div {
    border-radius: var(--radii);
  }

  input {
    width: 100% !important;
    height: 100% !important;
  }

  &:has(div:hover),
  &:has(input:focus),
  &:has(input:checked) {
    div {
      background: var(--shade2-15);
      box-shadow: inset 0 0 0 1px var(--shade1-15);
    }
  }

  > div {
    padding: var(--sp-4);
    text-align: center;
    color: var(--white);
    box-shadow: inset 0 0 0 1px var(--shade2-15);

    @container (inline-size <= 750px) {
      padding: var(--sp-2);
    }
  }
`;

export const DepartmentPanels = styled("div")``;

export const DepartmentPanel = styled("ul")`
  display: grid;
  flex: 2;
  gap: var(--sp-4);
  z-index: 1;

  [aria-hidden="true"] {
    display: none;
  }
`;

export const JobPosition = styled("a")`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--sp-6);
  box-shadow: inset 0 0 0 1px var(--shade2-15);
  border-radius: var(--br-lg);
  cursor: pointer;

  > * {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: linear-gradient(to right, transparent, var(--dept-color));
    opacity: 0.05;
    border-radius: var(--br-lg);
    transition: opacity var(--transition);
  }

  &:hover {
    .job-title {
      text-dectoration: underline;
    }
    &::before {
      opacity: 0.15;
    }
  }

  & > .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @container (inline-size <= 750px) {
      display: grid;
      gap: var(--sp-3);
      align-items: flex-start;
    }

    span {
      position: relative;
      display: block;
      padding: var(--sp-1) var(--sp-2);
      color: var(--dept-color);
      white-space: nowrap;
      max-width: fit-content;

      @container (inline-size <= 750px) {
        order: -1;
      }

      &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: var(--dept-color);
        border-radius: var(--br);
        opacity: 0.15;
      }
    }
  }

  & > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    color: var(--shade1);

    li {
      display: flex;
      align-items: center;
    }

    li:not(:last-child)::after {
      content: "";

      display: block;

      width: var(--sp-1);
      height: var(--sp-1);
      margin: 0 var(--sp-2);

      background: var(--shade1);
      border-radius: 50%;
    }
  }
`;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
// import CareerBlitzIcon from "@/inline-assets/career-blitz.svg";
// import CareerChargeIcon from "@/inline-assets/career-charge.svg";
import CareerDollarIcon from "@/inline-assets/career-dollar.svg";
// import CareerFoodIcon from "@/inline-assets/career-food.svg";
// import CareerGameIcon from "@/inline-assets/career-game.svg";
// import CareerGlassesIcon from "@/inline-assets/career-glasses.svg";
import CareerGlobalIcon from "@/inline-assets/career-global.svg";
import CareerGrowthIcon from "@/inline-assets/career-growth.svg";
// import CareerGymIcon from "@/inline-assets/career-gym.svg";
import CareerInsuredIcon from "@/inline-assets/career-insured.svg";
// import CareerOfficeIcon from "@/inline-assets/career-office.svg";
// import CareerTreeIcon from "@/inline-assets/career-tree.svg";
import {
  DepartmentButton,
  DepartmentList,
  DepartmentPanel,
  DepartmentsListPanel,
  HeaderSection,
  JobPosition,
  JoinSection,
  MainSections,
  OpenPositionsSection,
  PageContainer,
  ReasoningSection,
} from "@/marketing/Careers.style.jsx";
import MarketingFooter from "@/marketing/MarketingFooter.jsx";
import MarketingHeader from "@/marketing/MarketingHeader.jsx";
import { Halo } from "@/shared/Halo.jsx";
import { useQuery } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const SPINNING_ROBOT = `${appURLs.CDN_VIDEOS}/ui/video/careers/spinning-robot-64dd27.webm`;

const REASONS: {
  title: Translation;
  desc: Translation;
  icon: React.ReactNode;
}[] = [
  {
    title: ["home:careers.reason12.title", "Growth"],
    desc: ["home:careers.reason12.desc", "Both personal and business."],
    icon: <CareerGrowthIcon className="original-colors" />,
  },
  {
    title: ["home:careers.reason7.title", "Equity"],
    desc: [
      "home:careers.reason7.desc",
      "Employees have equity in the business.",
    ],
    icon: <CareerDollarIcon className="original-colors" />,
  },
  {
    title: ["home:careers.reason9.title", `Insurance Options`],
    desc: [
      "home:careers.reason9.desc",
      "For your health, teeth, eyes, and pets.",
    ],
    icon: <CareerInsuredIcon className="original-colors" />,
  },
  // {
  //   title: ["home:careers.reason1.title", `It's Fun!`],
  //   desc: [
  //     "home:careers.reason1.desc",
  //     "We believe good fun leads to good work.",
  //   ],
  //   icon: <CareerBlitzIcon className="original-colors" />,
  // },
  {
    title: ["home:careers.reason2.title", `We're Global`],
    desc: ["home:careers.reason2.desc", "Top-tier gamers from 10+ countries."],
    icon: <CareerGlobalIcon className="original-colors" />,
  },
  // {
  //   title: ["home:careers.reason3.title", "The Office is Chill"],
  //   desc: [
  //     "home:careers.reason3.desc",
  //     `Or you can work remote, that's cool too.`,
  //   ],
  //   icon: <CareerOfficeIcon className="original-colors" />,
  // },
  // {
  //   title: ["home:careers.reason4.title", "Food!!!"],
  //   desc: [
  //     "home:careers.reason4.desc",
  //     "Infinite snacks and chef-cooked meals.",
  //   ],
  //   icon: <CareerFoodIcon className="original-colors" />,
  // },
  // {
  //   title: ["home:careers.reason5.title", "We Play Games"],
  //   desc: [
  //     "home:careers.reason5.desc",
  //     "Frequently and with incredible passion.",
  //   ],
  //   icon: <CareerGameIcon className="original-colors" />,
  // },
  // {
  //   title: ["home:careers.reason6.title", "We Like to Party"],
  //   desc: ["home:careers.reason6.desc", "We like. We like to party."],
  //   icon: <CareerGlassesIcon className="original-colors" />,
  // },
  // {
  //   title: ["home:careers.reason8.title", "All the Time Off"],
  //   desc: ["home:careers.reason8.desc", "Take as much PTO as ya need, friend."],
  //   icon: <CareerTreeIcon className="original-colors" />,
  // },
  // {
  //   title: ["home:careers.reason10.title", "Gym Time"],
  //   desc: [
  //     "home:careers.reason10.desc",
  //     "Get swole then shower off your sweat(s).",
  //   ],
  //   icon: <CareerGymIcon className="original-colors" />,
  // },
  // {
  //   title: ["home:careers.reason11.title", "Charrrrrrge"],
  //   desc: [
  //     "home:careers.reason11.desc",
  //     "...your electric car free at the office lot.",
  //   ],
  //   icon: <CareerChargeIcon className="original-colors" />,
  // },
];

const colors = ["var(--purple)", "var(--orange)", "var(--lime)", "var(--blue)"];

export default function Careers() {
  const { t } = useTranslation();
  const { marketing } = useSnapshot(readState);
  const openPositionsSection = useRef(null);
  const [clickedDepartment, setClickedDepartment] = useState<boolean>(false);

  const departments = useMemo(() => {
    if (!marketing?.departments?.length) return [];

    const availableDpts = marketing.departments.filter(
      (dpt) => dpt.jobs.length > 0,
    );

    // setSelectedDpt(availableDpts?.[0]?.id || "");

    return availableDpts;
  }, [marketing?.departments]);

  // const [selectedDepartment, setSelectedDepartment] = useState<number>(null);
  const [selectedDepartment, setSelectedDepartment] = useQuery<string>(
    "department",
    "",
  );

  useEffect(() => {
    if (
      !selectedDepartment ||
      !openPositionsSection?.current ||
      clickedDepartment
    )
      return;

    openPositionsSection.current.scrollIntoView();
  }, [selectedDepartment, openPositionsSection, clickedDepartment]);

  const onChangeActiveDepartmentTab = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDepartment(e.target.value);
      setClickedDepartment(true);
    },
    [setSelectedDepartment],
  );

  return (
    <PageContainer>
      <MarketingHeader />

      <MainSections>
        <HeaderSection>
          <div className="video-block">
            <Halo size="90%" blur={48} fadeOut />
            <video
              loop
              autoPlay
              preload="metadata"
              controlsList="nodownload"
              muted
              height="350px"
            >
              <source src={`${SPINNING_ROBOT}`} type="video/mp4" />
            </video>
          </div>

          <div className="text-block">
            <h1>{t("home:careers.title", "Careers")}</h1>
            <p>
              {t(
                "home:careers.description",
                `We're always looking for talented people to join our team.`,
              )}
            </p>
          </div>
        </HeaderSection>

        <ReasoningSection>
          <ul>
            {REASONS.map((reason, index) => (
              <li key={index}>
                <div className="icon-block">{reason.icon}</div>
                <div className="text-block">
                  <h5 className="type-h5">{t(...reason.title)}</h5>
                  <p className="type-article-headline">{t(...reason.desc)}</p>
                </div>
              </li>
            ))}
          </ul>
        </ReasoningSection>

        <JoinSection>
          <div>
            <h3>{t("home:careers.joinTheSquad.title", "Join The Team")}</h3>

            <p>
              {t(
                "home:careers.joinTheSquad.desc",
                "We're constantly inspired by the future of gaming and eager to be a part of it. Join us if you want to make great things, learn new skills, and rank up.",
              )}
            </p>
          </div>
        </JoinSection>

        <OpenPositionsSection>
          <div className="container" ref={openPositionsSection}>
            <DepartmentsListPanel>
              <div className="title-block">
                <h4 className="type-h5">
                  {t("home:careers.position.title", "Browse Open Positions")}
                </h4>

                <p className="type-body1">
                  {t(
                    "home:careers.position.desc",
                    "Select a department to filter open positions.",
                  )}
                </p>
              </div>

              <div style={{ width: "100%" }}>
                <DepartmentList role="tablist" aria-label="Features Tabs">
                  {departments.map(({ name }) => (
                    <DepartmentButton
                      role="tab"
                      key={name}
                      className={name === selectedDepartment && "active"}
                    >
                      <input
                        role="button"
                        id={`tab-${name}`}
                        aria-controls={`tab-panel-${name}`}
                        className="visually-hidden"
                        type="radio"
                        name="department"
                        value={name}
                        defaultChecked={name === selectedDepartment}
                        onChange={onChangeActiveDepartmentTab}
                      />

                      <div className="type-form--button">{name}</div>
                    </DepartmentButton>
                  ))}
                </DepartmentList>
              </div>
            </DepartmentsListPanel>

            <DepartmentPanel>
              {departments.map(({ id, name, jobs }, depIndex) => (
                <React.Fragment key={id}>
                  {jobs.map(
                    (
                      { type, title, location, absolute_url, updated_at },
                      index,
                    ) => {
                      const locRegion = location.name.split(",")?.[0];
                      const locCountry = location.name.split(",")?.[1];

                      return (
                        <li
                          key={`${id}-${index}`}
                          aria-hidden={
                            selectedDepartment
                              ? name !== selectedDepartment
                              : false
                          }
                          style={{
                            "--dept-color":
                              colors[depIndex] || "var(--primary)",
                          }}
                        >
                          <JobPosition href={absolute_url} target="_blank">
                            <div className="title-block">
                              <h6 className="job-title type-h5">{title}</h6>
                              <span className="type-subtitle--bold">
                                {name}
                              </span>
                            </div>

                            <ul className="type-body1">
                              {locRegion && <li>{locRegion}</li>}
                              {locCountry && <li>{locCountry}</li>}
                              <li>{type}</li>
                              <li>
                                {new Date(updated_at).toLocaleDateString()}
                              </li>
                            </ul>
                          </JobPosition>
                        </li>
                      );
                    },
                  )}
                </React.Fragment>
              ))}
            </DepartmentPanel>
          </div>
        </OpenPositionsSection>
      </MainSections>

      <MarketingFooter expanded />
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["home:meta.careers.title", "Blitz.gg - Careers in Gaming"],
    description: [
      "home:meta.careers.description",
      "Join us at Blitz and rank up IRL! We're hiring for positions around the globe.",
    ],
  };
}
